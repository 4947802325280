<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Obrázky - Upravit <span class="utils__link--underlined text-primary">#{{ id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Obrázek">
          <div :class="$style.gallery">
            <div :class="$style.item">
              <img v-if="!isImageError && detail && detail.iae_path" class="img-fluid img-size-6" v-lazy="$uploadImagePath + detail.iae_path + getImageSize(detail, 6) + '.' + detail.iae_format" @error="isImageError = true">
              <a-icon v-else theme="twoTone" twoToneColor="#ccc" type="frown" />
            </div>
          </div>
        </a-form-item>

        <a-form-item label="Formát">
          <p>{{ detail.iae_format }}</p>
        </a-form-item>

        <a-form-item
          label="Název"
          :validate-status="error('iae_name') ? 'error' : ''"
          :help="error('iae_name') || ''"
        >
          <a-input
            v-decorator="[`iae_name`, {rules: [{max: 100, message: 'Název nemůže být delší než 100 znaků!'}, {required: true, message: 'Název musí být vyplněna!'}]}]"
            placeholder="Název"
          />
        </a-form-item>

        <a-form-item
          label="Kategorie"
          :validate-status="error('icy_id') ? 'error' : ''"
          :help="error('icy_id') || ''"
        >
          <a-select
            v-decorator="[`icy_id`, {rules: [{required: true, message: 'Kategorie musí být vyplněny!'}]}]"
            showSearch
            mode="multiple"
            placeholder="-- Vyber kategorii --"
            :options="categories.map(x => { return { value: x.icy_id, label: x.icy_name }})"
            optionFilterProp="children"
            :filterOption="filterOption"
            :loading="!loaded.includes('icy')"
          />
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'

export default {
  mixins: [imageSizeMixin],
  components: { ActionTools },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/image',
          title: 'Seznam',
        },
      ],
      form: this.$form.createForm(this),
      id: this.$route.params.id,
      loaded: [],
      isImageError: false,
    }
  },
  computed: {
    detail: function () {
      return this.$store.getters['image/getDetail']
    },
    categories: function () {
      return this.$store.getters['imageCategory/filteredItems']('')
    },
  },
  methods: {
    getData() {
      return {
        icy_ids: this.form.getFieldValue('icy_id'),
        iae_name: this.form.getFieldValue('iae_name'),
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('image/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    initData() {
      this.loading = true
      Promise.all([
        this.$store.dispatch('imageCategory/getList'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {})
        .finally(() => {
          this.loaded.push('icy')
          this.loading = false
        })
    },
    initDetail() {
      this.$store.dispatch('image/getOne', this.id)
        .then(() => {
          this.form.setFieldsValue({
            icy_id: this.detail.image_categories.map(x => x.icy_id),
            iae_name: this.detail.iae_name,
          })
        })
        .catch(() => {})
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.loaded = []
    },
  },
  created() {
    this.initData()
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      this.initDetail()
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
